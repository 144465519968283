import * as React from 'react';

import useTranslation from '../hooks/useTranslation';
import Heading from '../components/Heading';

function ContactContent() {
  const { t, translations } = useTranslation();

  return (
    <div className="sticky top-32 space-y-8 text-sm">
      <Heading level="h2" visualLevel="h5" weight="bold">
        {t('COMMON.CONTACT_TITLE')}
      </Heading>
      <p
        dangerouslySetInnerHTML={{ __html: t('COMMON.CONTACT_DESCRIPTION') }}
      />
      <ul className="space-y-4">
        {translations.COMMON.CONTACT_CONTENT.map((text, index) => (
          <li key={index}>
            <h3 className="font-bold">{text.TYPE}</h3>
            <div>
              {text.VALUE.map((value, index) => (
                <p key={index}>{value}</p>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ContactContent;
