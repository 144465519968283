import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../containers/Layout';
import Seo from '../components/Seo';
import useTranslation from '../hooks/useTranslation';
import pages from '../../config/pages';
import Content from '../containers/Content';

function Menu({ pageContext: { currentPath } }) {
  const { t, translations, locale } = useTranslation();

  return (
    <Layout background="hero" title={t('MENU.TITLE')}>
      <Seo title={t('SEO.MENU.TITLE')} location={currentPath} lang={locale} />

      <Content title={t('MENU.TITLE')}>
        <div>
          {translations.MENU.DESCRIPTION.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>

        <ul>
          {pages
            .find((page) => page.template === 'Menu')
            .children.map((child) => (
              <li key={child.id}>
                <Link
                  to={child[locale].url}
                  className="underline hover:no-underline font-bold underline-offset-2"
                >
                  {t(`NAVIGATION.${child.id}`)}
                </Link>
              </li>
            ))}
        </ul>
      </Content>
    </Layout>
  );
}

export default Menu;
