import * as React from 'react';

import Container from '../components/Container';
import ContactContent from '../components/ContactContent';
import Heading from '../components/Heading';

function Content({ children, title }) {
  return (
    <Container className="py-12 md:py-[10vh]">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-24">
        <div className="col-span-1 md:col-span-2 space-y-8">
          <Heading
            level="h2"
            visualLevel="h3"
            weight="bold"
            className="hidden md:block"
          >
            {title}
          </Heading>

          {children}
        </div>
        <div className="col-span-1">
          <ContactContent />
        </div>
      </div>
    </Container>
  );
}

export default Content;
